/* .newtable thead{
    background-color: coral;
  
}
.newtable span{
    background-color: coral;
  
} */
.current{
   
    background: red !important;
    border-color: red !important; 
    font-weight: bold;

}

[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.fred{
  /* background: '#5d899f'!important; */
  box-shadow: 0 0 0 35px #5d899f inset;
  color:white;
  border-radius: 10px;;
}