input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}iframe {
    display: block;
    width: 100%;
    max-width: 60rem;
    height: 100%;
    margin: 1rem auto;
    border: 1px solid black;
  }