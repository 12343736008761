@import url('https://fonts.googleapis.com/css?family=Ubuntu:700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: none;
}

.form {
  width: 720px;
  height: 500px;
  margin: 50px auto;

  padding: 45px 65px;
  background: linear-gradient(to right, #8300cd, #b800c4);
  &__box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
    border-radius: 40px;

  }
  &__left {
    width: 50%;
       
  }
  &__padding {
    width: 210px;
    height: 210px;
    background: #f2f2f2;
    border-radius: 50%;
    margin: 0 auto;
    line-height: 210px;
    position: relative;
  }
  &__image {
    max-width: 100%;
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  &__right {
    line-height: 26px;
    width: 50%;
  }
  &__padding-right {
    padding: 0 25px;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    
    text-align: center;
    margin-bottom: 30px;
  }
  &__submit-btn {
    background: #1fcc44;
    cursor: pointer;
      &:hover {
        background: #ff3f70;
        }
  }
  &__email {
    position: relative;

  }

}
.input {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  height: 35px;
  border-radius: 20px;
  border: none;
  background: #f2f2f2;
  padding: 10px;
  font-size: 14px;
  position: relative;
  &:after {
    position: absolute;
    content: 'a***';
  }
}
.a {
  color: #71df88;
  position: relative;
  &:hover {
    color: #ff3f70;
  }

}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}