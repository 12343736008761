@import url(https://fonts.googleapis.com/css?family=Ubuntu:700&display=swap);
.select:hover {
  background-color: #4d94ff;
  font-weight: bold;
}

table {
    text-align: left;
    position: relative;
    border-collapse: collapse; 
  }
  th {
    padding: 0.25rem;
    position:-webkit-sticky;
    position:sticky
  }
*{margin:0;padding:0;box-sizing:border-box;font-family:"Ubuntu",sans-serif;text-decoration:none}.form{width:720px;height:500px;margin:50px auto;padding:45px 65px;background:linear-gradient(to right, #8300cd, #b800c4)}.form__box{width:100%;height:100%;display:flex;justify-content:space-around;align-items:center;background:#fff;border-radius:40px}.form__left{width:50%}.form__padding{width:210px;height:210px;background:#f2f2f2;border-radius:50%;margin:0 auto;line-height:210px;position:relative}.form__image{max-width:100%;width:60%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.form__right{line-height:26px;width:50%}.form__padding-right{padding:0 25px}.form__title{font-size:18px;font-weight:bold;text-align:center;margin-bottom:30px}.form__submit-btn{background:#1fcc44;cursor:pointer}.form__submit-btn:hover{background:#ff3f70}.form__email{position:relative}.input{display:block;width:100%;margin-bottom:25px;height:35px;border-radius:20px;border:none;background:#f2f2f2;padding:10px;font-size:14px;position:relative}.input:after{position:absolute;content:"a***"}.a{color:#71df88;position:relative}.a:hover{color:#ff3f70}input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0}
/* .newtable thead{
    background-color: coral;
  
}
.newtable span{
    background-color: coral;
  
} */
.current{
   
    background: red !important;
    border-color: red !important; 
    font-weight: bold;

}

[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.fred{
  /* background: '#5d899f'!important; */
  box-shadow: 0 0 0 35px #5d899f inset;
  color:white;
  border-radius: 10px;;
}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0}
/* .newtable thead{
    background-color: coral;
  
}
.newtable span{
    background-color: coral;
  
} */
.current{
   
    background: red !important;
    border-color: red !important; 
    font-weight: bold;

}

[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.fred{
  /* background: '#5d899f'!important; */
  box-shadow: 0 0 0 35px #5d899f inset;
  color:white;
  border-radius: 10px;;
}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0}iframe{display:block;width:100%;max-width:60rem;height:100%;margin:1rem auto;border:1px solid #000}
